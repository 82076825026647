import { h } from "preact";

interface FooterLinkProps {
  link: {
    url: string;
    title: string;
  }
  iconName: string;
}
const FooterLink = ({link, iconName}: FooterLinkProps) => (
  <a
    class="bg-primary rounded-full h-12 w-12 flex justify-center items-center transition-transform hover:shadow-lg hover:scale-110 mx-2 select-none"
    href={link.url}
    title={link.title}
    target="_blank"
  >
    <img src={`/assets/vectors/${iconName}.svg`} class="w-1/2"/>
  </a>
);

export default FooterLink;