import { h } from 'preact';
import { Route, Router, route } from 'preact-router';

import Header from './header';
import Footer from './footer';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Service from '../routes/service';

const RedirectHome = () => {
	route('/', true);
	return null;
}

const App = () => (
	<div id="app" class="flex flex-col min-h-screen">
    <Header />
		<main class="flex-1 bg-slate-50 pb-6">
			<Router>
				<Route path="/" component={Home} />
				<Route path="/service/:serviceKey" component={Service} />
				<Route component={RedirectHome} default />
			</Router>
		</main>
		<Footer />
	</div>
);

export default App;
