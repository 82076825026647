import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import FooterLink from "./subcomponents/FooterLink";
import EmailActions from "./subcomponents/EmailActions";

const LINKS = {
  linkedin: {
    url: 'https://www.linkedin.com/in/johnjosephtaylor/',
    title: 'LinkedIn | John Joseph Taylor'
  },
  github: {
    url: 'https://github.com/YcleptJohn',
    title: 'Github | YcleptJohn'
  },
}

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showEmailActions, setShowEmailActions] = useState<boolean>(false);

  useEffect(() => {
    let timeout, interval;
    if(isExpanded) {
      // When expanding the footer past the bottom overflow anchor we need to scroll with it
      interval = setInterval(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight
        });
      }, 10);
      timeout = setTimeout(() => clearInterval(interval), 300);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (timeout) {
        clearInterval(timeout);
      }
    }
  }, [isExpanded]);

  return (
    <div class={`bg-slate-50 transition-height overflow-hidden pt-4 -translate-y-4 ${isExpanded ? 'h-[220px]' : 'h-[100px]'}`}>
      <div class="group relative h-0.5 w-full bg-gray-200 hover:bg-gray-300 select-none" onClick={() => setIsExpanded(!isExpanded)}>
        <div class="absolute left-1/2 -translate-x-1/2 -top-4 bg-white border-2 border-gray-200 group-hover:border-gray-300 rounded-full w-8 h-8 flex justify-center">
          <img src={'/assets/vectors/chevron-up.svg'} class={`w-4 transition-transform ${isExpanded ? 'rotate-180' : 'rotate-0'}`}/>
        </div>
      </div>
      <div class="bg-white h-[100px] flex flex-row justify-center items-center">
        <img src="/assets/logo.svg" class="w-44"/>
        <p>&nbsp;by&nbsp;</p>
        <a
          class="text-blue-600 font-semibold hover:text-blue-800 hover:underline"
          href={LINKS.linkedin.url}
          title={LINKS.linkedin.title}
          target='_blank'>John Taylor</a>
      </div>
      <div class="bg-white h-[120px] flex flex-col items-center">
        <div class="flex flex-row items-center">
          <FooterLink link={LINKS.linkedin} iconName="linkedin" />
          <div>
            <div
              class={`bg-primary flex justify-center items-center transition-width hover:shadow-lg hover:scale-110 mx-2 h-12 ${showEmailActions ? 'w-48 rounded-md scale-110' : 'w-12 rounded-full'}`}
              onClick={() => setShowEmailActions(!showEmailActions)}
            >
              <img src={'/assets/vectors/email.svg'} class="h-1/2 select-none"/>
              <p
                class={`overflow-hidden text-sm text-white ml-1 ${!showEmailActions && 'hidden'}`}
                onClick={(e) => e.stopPropagation()}
              >johntaylorjjt@gmail.com</p>
            </div>
          </div>
          <FooterLink link={LINKS.github} iconName="github" />
        </div>
        <EmailActions shouldShow={showEmailActions} />
      </div>
    </div>
  );
}


export default Footer;