import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

interface EmailActionsProps {
  shouldShow: boolean;
}
const EmailActions = ({shouldShow}: EmailActionsProps) => {
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  useEffect(() => {
    let timeout;
    if (!shouldShow) {
      timeout = setTimeout(() => {
        setHasCopied(false);
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [shouldShow]);

  return (
    <div class={`flex justify-around w-40 transition-transform mt-2 ${shouldShow ? 'scale-100' : 'scale-0'}`}>
      <button
        class={`${hasCopied ? 'bg-green-600' : 'bg-blue-500'} rounded-full px-3 h-8 transition-transform hover:drop-shadow-md hover:scale-105 text-white text-sm`}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText('johntaylorjjt@gmail.com');
            setHasCopied(true);
          } catch (e) {
            console.warn('Failed to copy to clipboard, email is johntaylorjjt@gmail.com');
          }
        }}
      >{hasCopied ? 'Copied!' : 'Copy'}</button>
      <a 
        class="bg-gray-800 rounded-full px-3 h-8 transition-transform hover:drop-shadow-md hover:scale-105 text-white text-sm flex flex-col justify-center"
        href="mailto:johntaylorjjt@gmail.com"
      >Compose</a>
    </div>
  );
}

export default EmailActions;