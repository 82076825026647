import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

// const Header = () => (
// 	<header class={style.header}>
// 		<a href="/" class={style.logo}>
// 			<img src="../../assets/preact-logo-inverse.svg" alt="Preact Logo" height="32" width="32" />
// 			<h1>Preact CLI</h1>
// 		</a>
// 		<nav>
// 			<Link activeClassName={style.active} href="/">
// 				Home
// 			</Link>
// 			<Link activeClassName={style.active} href="/profile">
// 				Me
// 			</Link>
// 			<Link activeClassName={style.active} href="/profile/john">
// 				John
// 			</Link>
// 		</nav>
// 	</header>
// );

const Header = () => (
	<header class="flex flex-row justify-center border-b-2">
		<nav role='navigation' aria-label='main navigation'>
			<a href="/" class="block py-1 px-10 select-none">
				<img src="../../assets/logo.svg" alt="Statuses.tech logo" />
			</a>
		</nav>
	</header>
)

export default Header;
